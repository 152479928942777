import axios from "axios";

const host = (typeof window == 'object' && (localStorage.getItem("env") == 'dev' || window.location.hostname == 'localhost') ) ? "https://8i85gq7ftj.execute-api.us-east-1.amazonaws.com/v1" : "https://api.scispot.io/tryingtofixcors";

export const logActivity = (uuid, operation) => {

    let payload = {
        "action": "LOG_ACTIVITY",
        "customerId": localStorage.getItem("customerId"),
        "token": localStorage.getItem("token"),
        "activityAction": operation?.toUpperCase(),
        "fileType": "CHEM EDITOR",
        "actionItemUuid": uuid,
        "message": localStorage.getItem("email") + " - " + operation + " - chem editor",
    }

    return axios.post(`${host}/activity`, payload)    

}