import axios from "axios";
const indigo_host = "https://indigo.scispot.io/v2";

export const getMoleculeSmile = (moleculeStrucure, callback) => {

    if(typeof window != "object") return

    const payload =  {
        "struct": moleculeStrucure,
        "output_format":"chemical/x-daylight-smiles",
        "options":{
           "smart-layout":true,
           "ignore-stereochemistry-errors":true,
           "mass-skip-error-on-pseudoatoms":false,
           "gross-formula-add-rsites":true,
           "aromatize-skip-superatoms":true,
           "dearomatize-on-load":false,
           "gross-formula-add-isotopes":true
        }
    }

    axios.post(`${indigo_host}/indigo/convert`, payload)
    .then(resp => {
  
        if(resp) {
            callback(resp?.data?.struct ?? "")
        } else {
            callback("error")
        }
    
    }).catch(e=>{
        callback("error")
    })
  
}
