import axios from "axios";
import { logActivity } from "../activity";

const host = (typeof window == 'object' && (localStorage.getItem("env") == 'dev' || window.location.hostname == 'localhost') ) ? "https://8i85gq7ftj.execute-api.us-east-1.amazonaws.com/v1" : "https://api.scispot.io/tryingtofixcors";

export const saveChemData = (token, uuid, smiles, callback) => {

    if(typeof window != "object") return

    logActivity(uuid, "save")

    const payload =  {
        "action": "SET_CHEM_DATA",
        "token": token,
        "uuid": uuid,
        "smiles": smiles
    }

    axios.post(`${host}/sequence/json`, payload)
    .then(resp => {
  
        if(resp) {
            if(callback) callback()
        } else {
            callback("error")
        }
    
    }).catch(e=>{
        callback("error")
    })


}

export const getChemData = (token, uuid, callback) => {

    if(typeof window != "object") return

    logActivity(uuid, "fetch")

    const payload =  {
        "action": "GET_CHEM_DATA",
        "token": token,
        "uuid": uuid
    }

    axios.post(`${host}/sequence/json`, payload)
    .then(resp => {
  
        if(resp) {
            if(callback) callback(resp?.data?.smiles ?? "")
        } else {
            callback("")
        }
    
    }).catch(e=>{
        callback("")
    })

}