import { v4 as uuidv4 } from "uuid";
import { getCurrentUserInfo } from '../helper';
import axios from "axios";

const host = "https://api.scispot.io/tryingtofixcors";
const host_dev = "https://8i85gq7ftj.execute-api.us-east-1.amazonaws.com/v1";

export const logEvent = (event_name, event_message) => {

    if(typeof window != "object") return

    let userName = localStorage.getItem("email")

    let now = new Date();
    let logObj = {
      action: event_name,
      Id: uuidv4(),
      message: event_message,
      timestamp: now.toISOString(),
      user: {
        user: getCurrentUserInfo()?.id,
        userName: userName,
        customer: localStorage.getItem("customerId"),
        customerName: getCurrentUserInfo()?.customerName,
        token: localStorage.getItem("myToken"),
        session: localStorage.getItem("myToken"),
      },
      client: {
        uri: window?.location?.hostname,
      },
    };

    if(typeof window == 'object' && (localStorage.getItem("env") == 'dev' || window.location.hostname == 'localhost') ) {
      axios.post(`${host_dev}/log/add`, logObj)
    } else {
      axios.post(`${host}/log/add`, logObj)
    }

}

export const setSession = (token, callback) => {

  const payload = {
      "token": token
  }

  const selected_host = (typeof window == 'object' && (localStorage.getItem("env") == 'dev' || window.location.hostname == 'localhost') ) ? host_dev : host

  axios.post(`${selected_host}/get-current-session-data`, payload)
  .then(resp => {

      if(resp) {
        localStorage.setItem("profile", resp?.data?.profile)
        localStorage.setItem("badge", resp?.data?.badge)
        localStorage.setItem("customerName", resp?.data?.customerName)
        localStorage.setItem("customerPoolId", resp?.data?.customerPoolId)
        localStorage.setItem("customerReference", resp?.data?.customerReference)
        localStorage.setItem("customerId", resp?.data?.customerReference)
        localStorage.setItem("myToken", token)
        localStorage.setItem("current_user_info", resp?.data?.userInfo)
        localStorage.setItem("userInfo", resp?.data?.userInfo)
        localStorage.setItem("email", resp?.data?.email)        
        callback(resp?.data)
      } else {
          callback("error")
      }
  
  }).catch(e=>{
      callback("error")
  })

}
