export function getCurrentUserInfo() {
    if (typeof window != 'object') return;

    if(localStorage.getItem("current_user_info") && localStorage.getItem("current_user_info") != "undefined") {
      return JSON.parse(localStorage.getItem("current_user_info"));
    } else if(localStorage.getItem("userInfo")){
      return JSON.parse(localStorage.getItem("  "));
    } else {
      return null
    }
}