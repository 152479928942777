import React, { useEffect, useState } from "react";
import { setSession } from "../src/api/user";
import "./App.css";
import KetcherExample from "./KetcherExample";
import { getChemData } from "./api/chem";

function App() {

  const [token, setToken] = useState(null)
  const [uuid, setUuid] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [moleculeData, setMoleculeData] = useState(null)
  
  useEffect(() => {

    console.log("version 1.2.0")

    setLoading(true)

    if(typeof window !== 'object') return

    const queryParams = new URLSearchParams(window.location.search)

    setToken(queryParams.get("token"))
    setUuid(queryParams.get("uuid"))
    const isDevEnv = queryParams.get("env")
    localStorage.setItem("env", isDevEnv)

  }, [])
  
  useEffect(() => {
    if(token && uuid) {
      setSession(token, user => {
        setCurrentUser(user)
      })  
    }
  }, [token, uuid])

  useEffect(() => {
    if(currentUser) {
      getChemData(token, uuid, (molecule) =>{
        setMoleculeData(molecule)
      })
    }
  }, [currentUser])
  
  return (
    <>
      {loading ? <>Loading...</> : <></>}
      <KetcherExample token={token} uuid={uuid} setLoading={setLoading} setSaving={setSaving} molecule={moleculeData} />
    </>
  )
}

export default App;
