import React from "react";
import "miew/dist/miew.min.css";
import { RemoteStructServiceProvider, KetSerializer} from 'ketcher-core'
import { Editor } from "ketcher-react";
import "ketcher-react/dist/index.css";
import Miew from "miew";
import { getMoleculeSmile } from "./api/indigo";
import { saveChemData } from "./api/chem";

(window).Miew = Miew;

const indigoServer = "https://indigo.scispot.io/v2"
const structServiceProvider = new RemoteStructServiceProvider(indigoServer,{})

export class KetcherExample extends React.Component {

  ketcher;  

  saveMolecule = (moleculeStrucure) => {
    
    const uuid = this.props.uuid
    const token = this.props.token
    const setSaving = this.props.setSaving

    if(setSaving) setSaving(true)

    getMoleculeSmile(moleculeStrucure, (smiles) => {
        saveChemData(token, uuid, smiles, () => {
            if(setSaving) setSaving(false)
        })
    })

  }

  constructor(props) {
    super(props)
  }

  handleOnInit = async (ketcher) => {
    try {
        this.ketcher = ketcher;
        (window).ketcher = ketcher;    
        (window).ketcher.setMolecule(this.props.molecule)
        this.props.setLoading(false)
        ketcher.editor.subscribe('change',  operations => { 
            const molecule = this.ketcher.editor.render.ctab.molecule.clone()
            const serializer = new KetSerializer()
            var moleculeData = serializer.serialize(molecule)
            this.saveMolecule(moleculeData)
         })
    } catch (e) {
        console.log("error", e)
    }
  }

  render() {
    return (
        <>
        { this?.props?.molecule != null ? 
            <Editor
                staticResourcesUrl={""}
                structServiceProvider={structServiceProvider}
                onInit={this.handleOnInit}
            /> : <></>
        }
        </>
    )
  }
}

export default KetcherExample;